import http from '@/units/request'
/** *
 * 获取工作台应用
 * ***/
export const getWorkTableList = () => {
  return http({
    url: '/is-system/application/v2/list',
    method: 'GET'
  })
}
/** *
 * 获取工作台最近使用
 * ***/
export const getApplicationHistory = () => {
  return http({
    url: '/is-system/application/get-application-history',
    method: 'GET'
  })
}
/** *
 * 新增工作台最近使用
 * ***/
export const addApplicationHistory = (data) => {
  return http({
    url: '/is-system/application/post-application-history',
    method: 'POST',
    data
  })
}
