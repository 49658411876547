<template>
  <div class="workTable">
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div class="taskGrid">
      <van-grid
        clickable
        :column-num="4"
        :border="false"
      >
        <van-grid-item
          :icon="require('@/assets/workTable/pending.svg')"
          text="待处理"
          @click="$toast('该功能暂未开放!')"
        />
        <van-grid-item
          :icon="require('@/assets/workTable/processed.svg')"
          text="已处理"
          @click="$toast('该功能暂未开放!')"
        />
        <van-grid-item
          :icon="require('@/assets/workTable/initiated.svg')"
          text="已发起"
          @click="$toast('该功能暂未开放!')"
        />
        <van-grid-item
          :icon="require('@/assets/workTable/received.svg')"
          text="我收到"
          @click="$toast('该功能暂未开放!')"
        />
        <!-- <van-grid-item
          :icon="require('@/assets/workTable/pending.svg')"
          text="待处理"
          to="/workTable/task/pendingTaskList"
          badge="99+"
        />
        <van-grid-item
          :icon="require('@/assets/workTable/processed.svg')"
          text="已处理"
          to="/workTable/task/processedTaskList"
        />
        <van-grid-item
          :icon="require('@/assets/workTable/initiated.svg')"
          text="已发起"
          @click="$toast('该功能暂未开放!')"
          to="/workTable/task/initiatedTaskList"
        />
        <van-grid-item
          :icon="require('@/assets/workTable/received.svg')"
          text="我收到"
          to="/workTable/task/archivedTaskList"
          badge="8"
        /> -->
      </van-grid>
    </div>
    <div
      class="noticeBar"
      v-if="messageObj.length"
    >
      <van-notice-bar
        left-icon="volume-o"
        background="#fffbe8"
        :scrollable="false"
      >
        <van-swipe
          :touchable="false"
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item
            @click="swipeHandler(item)"
            v-for="(item, index) in messageObj"
            :key="index"
          >
            <template>
              <p
                v-html="item.title"
                class="moreEllipsis"
              ></p>
            </template>
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <div class="moudleGrid">
      <van-collapse v-model="activeNames">
        <van-collapse-item
          title="最近使用"
          name="menuHistory"
          v-if="menuHistory.length"
        >
          <van-grid
            clickable
            :column-num="4"
            :border="false"
          >
            <template v-for="(item, index) in menuHistory">
              <van-grid-item
                v-if="item.route !== '/workTable/employeeCard'"
                :icon="'/api' + item.iconPath"
                :text="item.name"
                :to="item.route"
                :key="index"
                @click="addHistory(item)"
              />
              <van-grid-item
                v-else-if="item.route === '/workTable/employeeCard'"
                :icon="'/api' + item.iconPath"
                :text="item.name"
                :key="index"
                @click="employeeCardHandler($store.state.user.profile.employeeCard, item, $store.state.user.profile.certificateValidityPeriodExpired)"
              />
            </template>
          </van-grid>
        </van-collapse-item>
        <van-collapse-item
          :title="item.name"
          :name="item.id"
          v-for="(item, index) in workTableList"
          :key="index"
        >
          <van-grid
            clickable
            :column-num="4"
            :border="false"
          >
            <template v-for="(childrenItem, key) in item.children">
              <van-grid-item
                v-if="childrenItem.route !== '/workTable/employeeCard'"
                :key="key"
                :icon="'/api' + childrenItem.iconPath"
                :text="childrenItem.name"
                :to="childrenItem.route"
                @click="addHistory(childrenItem)"
              />
              <van-grid-item
                v-else-if="childrenItem.route === '/workTable/employeeCard'"
                :key="key"
                :icon="'/api' + childrenItem.iconPath"
                :text="childrenItem.name"
                @click="employeeCardHandler($store.state.user.profile.employeeCard, childrenItem, $store.state.user.profile.certificateValidityPeriodExpired)"
              />
            </template>
          </van-grid>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { ImagePreview, Dialog } from 'vant'
import {
  getWorkTableList,
  getApplicationHistory,
  addApplicationHistory
} from '@/api/workTable'
import { getNoticeList } from '@/api/message'
export default {
  name: 'workTable',
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getWorkTableList()
      this.workTableList = [...data.data]
      const { data: data1 } = await getApplicationHistory()
      this.menuHistory = [
        ...data1.data.filter((item) => {
          return JSON.stringify(this.workTableList).includes(item.id)
        })
      ]
      this.activeNames = [
        ...this.activeNames,
        ...data.data.map((item) => {
          return item.id
        })
      ]
      this.loadingShow = false
    } catch (error) {
      this.loadingShow = false
      if (error?.response?.status === 401) {
        return
      }
      if (error.message.includes('timeout')) {
        this.$toast.fail({
          message: '网络不好，请稍后再试!',
          duration: 500
        })
        return
      }
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    } finally {
      getNoticeList().then(({ data }) => {
        data.data.forEach(item => {
          item.text = item.content
        })
        this.messageObj = data.data
      })
    }
  },
  data() {
    return {
      activeNames: ['menuHistory'],
      workTableList: [],
      menuHistory: [],
      loadingShow: false,
      messageObj: [],
      dialogTitle: '',
      dialogText: ''
    }
  },
  methods: {
    // 添加菜单历史
    addHistory(val) {
      addApplicationHistory({ applicationHistoryDto: [val] })
    },
    // 公告点击时间
    swipeHandler(item) {
      Dialog.alert({
        title: item.title,
        message: '<strong style="color:black">公告内容:</strong>' + item.text,
        confirmButtonColor: '#1989FA',
        messageAlign: 'left',
        className: 'noticeDialog'
      }).then(() => {
        // on close
      })
    },
    // 工作证展示
    employeeCardHandler(val, childrenItem, flag) {
      this.addHistory(childrenItem)
      if (flag) {
        this.$toast.fail({
          message: '当前工作证已过期，请联系管理员！',
          duration: 1000,
          forbidClick: true
        })
      } else {
        ImagePreview({
          images: [...val.map((item) => {
            return '/api/file/' + item.originalPath
          })],
          closeable: true
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.workTable {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;

  ::v-deep {
    .van-overlay {
      height: auto;
      top: 46px;
      bottom: 50px;

      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .noticeBar {
      margin-bottom: 10px;
      padding: 0 7px;

      .van-notice-bar {
        margin: 0 auto;
        border-radius: 5px;

        .notice-swipe {
          height: 40px;
          line-height: 40px;
        }
      }
    }

    .taskGrid {
      margin-bottom: 10px;
      // .van-grid {
      //   .van-grid-item {
      //     .van-grid-item__content {
      //       // background-color: transparent;
      //     }
      //   }
      // }
    }

    .moudleGrid {
      flex: 1;
      overflow: auto;
      padding: 0 7px;
      padding-bottom: 10px;

      .van-collapse-item {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }

        .van-cell__title {
          font-weight: 700;
        }

        .van-grid-item__content {
          .van-grid-item__text {
            flex: 1;
            text-align: center;
          }
        }

        .van-cell {
          background-color: white;
          border-radius: 5px;
        }

        .van-collapse-item__title--expanded {
          border-radius: 5px 5px 0 0;
        }
      }

      .van-collapse-item__wrapper {
        border-radius: 0 0 5px 5px;
      }

      // .van-grid {
      //   // .van-grid-item {
      //   //   .van-grid-item__content {
      //   //     // background-color: transparent;
      //   //   }
      //   // }
      // }
      // .van-collapse-item__content {
      //   // background-color: transparent;
      // }
    }
  }
}
</style>
